
import { observer } from 'mobx-react';
import * as React from 'react';
import CittaTelemetry from '../../../common/telemetry/citta-telemetry';
import {
    createOrder
} from '../actions/laybuy-data-action';
import { showPaymentError } from '../payment.store';
import { IProp, IState } from './LaybuyButton.props';
import { generatePayload } from './LaybuyButton.utils';

const ButtonText:string = 'Proceed to Laybuy ';

/**
 * LaybuyButton component
 */
@observer class LaybuyButton extends React.Component<IProp, IState> {
    private cittaTelemetry: CittaTelemetry;
    constructor(props: IProp) {
        super(props);

        this.state = {
            isLoading: false
        };

        this.cittaTelemetry = new CittaTelemetry(this.props.buttonData.context.app.config.cittaInstrumentationKey);
    }

    public render(): JSX.Element {
        const { isLoading } = this.state;

        const callToActionProps = {
            className: 'btn btn-primary checkout-place-order__btn-place-order',
            title: isLoading ? 'Please wait ' : ButtonText,
            onClick: this.handleClick,
            glyph: isLoading ? 'spinner' : '',
            disabled: isLoading
        };

        return (
            <button {...callToActionProps}>
                {isLoading ? 'Please wait ' : ButtonText}
            </button>
        );
    }

    private handleClick = async () => {
        this.setState({
            isLoading: true
        });
        showPaymentError(false);

        try {
            const payload = generatePayload(this.props.buttonData);
            this.logMessage(`Completed Laybuy generatePayload - returnURL: ${payload.returnUrl} amountDue: ${payload.amount} taxAmount: ${payload.tax}
                routes: ${JSON.stringify(this.props.buttonData.actionContext.requestContext.app.routes)}
                sitePath: ${this.props.buttonData.actionContext.requestContext.sitePath}
                page url: ${window.location.href}
                location.pathname: ${window.location.pathname}
            `);
            const actionInput = await createOrder(
                payload, this.props.buttonData.actionContext
            ).catch((error: string | undefined) => {
                showPaymentError(true, error);
                this.setState({isLoading: false});
                throw error;
            });

            if (actionInput.result === 'SUCCESS') {
                this.props.onClick();
                return this.handleRedirect(actionInput.paymentUrl);
            } else {
                showPaymentError(true, actionInput.error);
                this.setState({isLoading: false});
            }
        } catch (e) {
            this.logError(e);
            showPaymentError(true, e);

            this.setState({isLoading: false});
        }
    };

    private handleRedirect = (url: string = '') => {
        window.location.href = url;
    };


    private createLogMessage = (message: string): string => {
        const { Id, OrderNumber, TotalItems } = this.props.buttonData.data.checkout.result!.checkoutCart.cart;
        const url: string = decodeURI(window.location.search);
        const urlParams = new URLSearchParams(url);
        const redirectCartId = urlParams.has('cartid') ? urlParams.get('cartid') : 'N/A';
        const origin = window.location.origin;

        const logMessage = `Message: - ${message} Origin: ${origin} - CartId: ${Id} - RedirectCartId: ${redirectCartId} - ChannelRefId: ${OrderNumber || 'N/A'} - TotalItem: ${TotalItems || 'N/A'}`;
        return logMessage;
    }

    private logError = (error: Error): void => {
        const logMessage = this.createLogMessage('Error');
        this.cittaTelemetry.trace(logMessage);
        this.cittaTelemetry.exception(error, logMessage);
        this.props.buttonData.context.telemetry.error(logMessage);
    }
    private logMessage = (message: string): void => {
        const logMessage = this.createLogMessage(message); 
        this.cittaTelemetry.trace(logMessage);
        this.props.buttonData.context.telemetry.error(logMessage);
    }
}

export default LaybuyButton;
