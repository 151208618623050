import { observable } from 'mobx';
import { PaymentMethods } from './citta-checkout-payment-instrument-afterpay';
import { scrollToPaymentModule } from './components/citta-payment.utils';

export const paymentStore = observable({
    paymentMethod: '',
    checkoutErrorMessage: false,
    checkoutMessageText: '',
    checkoutErrorPaymentMethod: null
});

/**
 * Enables and highlights the Payment message block and displays a message
 * @param message the message to display
 *
 * @param paymentMthd the payment message on which to display this error - null for all methods
 */
export const showPaymentError = (displayError: boolean, message?: string, paymentMthd?: PaymentMethods) => {
    // @ts-ignore
    paymentStore.checkoutErrorPaymentMethod = paymentMthd || null;
    paymentStore.checkoutErrorMessage = displayError;
    if (displayError) {
        if (message && message !== '') {
            paymentStore.checkoutMessageText = message;
        } else {
            paymentStore.checkoutMessageText = 'Unspecified error occurred';
        }
    }
    if (displayError) {
        scrollToPaymentModule();
    }
};