import { IActionContext } from '@msdyn365-commerce/core';
import {
    CancelOrderInput,
    ICancelOrderAction
} from './laybuy-cancel-order-data-action';
import {
    ConfirmOrderInput,
    IConfirmOrderAction
} from './laybuy-confirm-order-data-action';
import {
    CreateOrderInput,
    ICreateOrderAction
} from './laybuy-create-order-data-action';

export const createOrder = async (payload: ICreateOrder, actionContext: IActionContext) => {
    try {
        return await ICreateOrderAction(
            new CreateOrderInput(payload),
            actionContext
        );
    } catch(e) {
        console.error('createOrder fail', e);
        throw e;
    }
};

export const confirmOrder = async (payload: string, actionContext: IActionContext) => {
    try {
        return await IConfirmOrderAction(
            new ConfirmOrderInput(payload),
            actionContext
        );
    } catch(e) {
        console.error('confirmOrder failed', e);
        throw e;
    }
};

export const cancelOrder = async (payload: string, actionContext: IActionContext) => {
    try {
        return await ICancelOrderAction(
            new CancelOrderInput(payload),
            actionContext
        );
    } catch(e) {
        console.error('confirmOrder failed', e);
        throw e;
    }
};

interface ICreateOrder {
    amount: number;
    tax: number;
    merchantReference: string;
}

export interface IItem {
    id: number;
    description: string;
    quantity: number;
    price: number;
}
