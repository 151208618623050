import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { IProductConfigErrorState } from '../../models/product-configure-error-state';

export function createProductConfigureErrorStateInput(result: IProductConfigErrorState): GenericInput<IProductConfigErrorState> {
    return new GenericInput<IProductConfigErrorState>('productConfigureErrorState', result, 'ProductConfigureErrorState');
}

const createProductConfigureErrorStateInputInteral = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return createProductConfigureErrorStateInput({ configureErrors: {} });
};

export default createObservableDataAction({
    action: <IAction<IProductConfigErrorState>>getGenericAction,
    input: createProductConfigureErrorStateInputInteral
});
