import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
} from '@msdyn365-commerce/core';
import { ICittaGiftCardBalanceCheckResult } from '../../modules/citta-gift-card-balance-check/citta-gift-card-balance-check.data';
import { getGiftCardAsync } from '../generated/DataActionExtension.g';
import { IDXC_GetGiftCardServiceResponse } from '../generated/DataServiceEntities.g';

/**
 * GiftCardBalanceCheckInput class
 */
export class GiftCardBalanceCheckInput implements IActionInput {
    public card: string;
    public pin: string;
    constructor(cardNumber: string, pin: string) {
        // blank
        this.card = cardNumber;
        this.pin = pin;
    }
    public getCacheKey = () => '';
    public getCacheObjectType = () => 'GiftCardBalanceCheckInput';
    public dataCacheType = (): CacheType => 'none';
}

export function createGiftCardBalanceCheckInput(options: { cardNumber: string; pin: string } = { pin: '', cardNumber: '' }): IActionInput {
    // blank
    return new GiftCardBalanceCheckInput(options.cardNumber, options.pin);
}

export async function getGiftCardBalanceCheck(
    input: GiftCardBalanceCheckInput,
    ctx: IActionContext
): Promise<ICittaGiftCardBalanceCheckResult> {
    let result: IDXC_GetGiftCardServiceResponse = {};
    try {
        result = await getGiftCardAsync({ callerContext: ctx }, input.card, input.pin);
    } catch (error) {
        ctx.telemetry.error(`${result.ErrorText}`);
        ctx.telemetry.error(`${error}`);
    }
    const giftCardresult: ICittaGiftCardBalanceCheckResult = {};
    giftCardresult.success = result.Success;
    giftCardresult.message = result.ErrorText;
    giftCardresult.balance = result.GiftCard && result.GiftCard.Balance;
    giftCardresult.currencyCode = result.GiftCard && result.GiftCard.CardCurrencyCode;
    return Array.isArray(giftCardresult) ? giftCardresult[0] : giftCardresult;
}

export default createObservableDataAction({
    action: <IAction<ICittaGiftCardBalanceCheckResult>>getGiftCardBalanceCheck,
    input: createGiftCardBalanceCheckInput
});
