import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';

export function createKeyedInPrice(result: number): GenericInput<number> {
    return new GenericInput<number>('buyboxKeyedInPrice', result, 'Number');
}

const createKeyedInPriceIternal = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return createKeyedInPrice(25);
};

export default createObservableDataAction({
    action: <IAction<number>>getGenericAction,
    input: createKeyedInPriceIternal
});