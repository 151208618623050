import { IProductInventoryInformation } from '@msdyn365-commerce-modules/retail-actions';
import { IActionContext } from '@msdyn365-commerce/core';
import { ProductAvailableQuantity, ProductWarehouseInventoryAvailability } from '@msdyn365-commerce/retail-proxy';

/**
 * The function that maps a ProductWareHouse object into a ProductAvailabilityQuantity
 */
export function mapProductInventoryInformation(ctx: IActionContext, productsWarehouseInventory?: ProductWarehouseInventoryAvailability[]): IProductInventoryInformation[] {
    const productInventoryInformation: IProductInventoryInformation[] = [];
    if (ctx.requestContext.app.config.enableStockCheck === undefined || ctx.requestContext.app.config.enableStockCheck === false) {
        return productInventoryInformation;
    }
    if (productsWarehouseInventory && productsWarehouseInventory.length > 0) {
        for (const product of productsWarehouseInventory) {
            switch (ctx.requestContext.app.config.inventoryLevel) {
                case 'physicalAvailable':
                    // Inventory level is enabled with Physical inventory
                    const physicalProductMap = mapInventoryWithPhysicalBufferEnabled(ctx, product);
                    if (physicalProductMap) {
                        productInventoryInformation.push(physicalProductMap);
                    }
                    break;
                case 'totalAvailable':
                    // Inventory level is enabled with Total available inventory
                    const totalAvailableProductMap = mapInventoryWithTotalAvailableBufferEnabled(ctx, product);
                    if (totalAvailableProductMap) {
                        productInventoryInformation.push(totalAvailableProductMap);
                    }
                    break;
                default:
                    // when inventory level is inventoryThreshold or not defined
                    const productMap = mapInventoryWithThresholdEnabled(ctx, product);
                    if (productMap) {
                        productInventoryInformation.push(productMap);
                    }
            }
        }
    }
    return productInventoryInformation;
}
export function mapInventoryWithThresholdEnabled(ctx: IActionContext, productWarehouseInventoryAvailability: ProductWarehouseInventoryAvailability): IProductInventoryInformation | null {
    if (productWarehouseInventoryAvailability && productWarehouseInventoryAvailability.ProductId && productWarehouseInventoryAvailability.TotalAvailable !== undefined) {
        const productQty = productWarehouseInventoryAvailability.TotalAvailable - ctx.requestContext.app.config.outOfStockThreshold;
        const productAvailableQuantity: ProductAvailableQuantity = {
            ProductId: productWarehouseInventoryAvailability.ProductId,
            AvailableQuantity: productQty > 0 ? productQty : 0,
            ExtensionProperties: productWarehouseInventoryAvailability.ExtensionProperties
        };
        return {
            ProductAvailableQuantity: productAvailableQuantity,
            IsProductAvailable: productAvailableQuantity.AvailableQuantity && productAvailableQuantity.AvailableQuantity > 0 ? true : false,
            InventLocationId: productWarehouseInventoryAvailability.InventLocationId,
        };
    }
    return null;
}
export function mapInventoryWithPhysicalBufferEnabled(ctx: IActionContext, productWarehouseInventoryAvailability: ProductWarehouseInventoryAvailability): IProductInventoryInformation | null {
    const shouldRenderLabel = displayLabelEnabled(ctx, productWarehouseInventoryAvailability.PhysicalAvailableInventoryLevelCode);
    if (productWarehouseInventoryAvailability && productWarehouseInventoryAvailability.ProductId && productWarehouseInventoryAvailability.TotalAvailable !== undefined) {
        // As per the new buffer logic from RS,
        // if code is out of stock then we set the available quantity to 0 regardless of the actual value from API
        const productQuantity = productWarehouseInventoryAvailability.PhysicalAvailableInventoryLevelCode === 'OOS'
            ? 0
            : productWarehouseInventoryAvailability.PhysicalAvailable;
        const productAvailableQuantity = {
            ProductId: productWarehouseInventoryAvailability.ProductId,
            AvailableQuantity: productQuantity,
            ExtensionProperties: productWarehouseInventoryAvailability.ExtensionProperties

        };
        return {
            ProductAvailableQuantity: productAvailableQuantity,
            StockLevelCode: shouldRenderLabel ? productWarehouseInventoryAvailability.PhysicalAvailableInventoryLevelCode : undefined,
            StockLevelLabel: shouldRenderLabel ? productWarehouseInventoryAvailability.PhysicalAvailableInventoryLevelLabel : undefined,
            IsProductAvailable: productAvailableQuantity.AvailableQuantity !== undefined && (productAvailableQuantity.AvailableQuantity > 0),
            InventLocationId: productWarehouseInventoryAvailability.InventLocationId
        };
    }
    return null;
}

export function mapInventoryWithTotalAvailableBufferEnabled(ctx: IActionContext, productWarehouseInventoryAvailability: ProductWarehouseInventoryAvailability): IProductInventoryInformation | null {
    const shouldRenderLabel = displayLabelEnabled(ctx, productWarehouseInventoryAvailability.TotalAvailableInventoryLevelCode);
    if (productWarehouseInventoryAvailability && productWarehouseInventoryAvailability.ProductId && productWarehouseInventoryAvailability.TotalAvailable !== undefined) {
        // As per the new buffer logic from RS,
        // if code is out of stock then we set the available quantity to 0 regardless of the actual value from API
        const productQuantity = productWarehouseInventoryAvailability.TotalAvailableInventoryLevelCode === 'OOS'
            ? 0
            : productWarehouseInventoryAvailability.TotalAvailable;
        const productAvailableQuantity = {
            ProductId: productWarehouseInventoryAvailability.ProductId,
            AvailableQuantity: productQuantity,
            ExtensionProperties: productWarehouseInventoryAvailability.ExtensionProperties
        };
        return {
            ProductAvailableQuantity: productAvailableQuantity,
            StockLevelCode: shouldRenderLabel ? productWarehouseInventoryAvailability.TotalAvailableInventoryLevelCode : undefined,
            StockLevelLabel: shouldRenderLabel ? productWarehouseInventoryAvailability.TotalAvailableInventoryLevelLabel : undefined,
            IsProductAvailable: productAvailableQuantity.AvailableQuantity !== undefined && (productAvailableQuantity.AvailableQuantity > 0),
            InventLocationId: productWarehouseInventoryAvailability.InventLocationId
        };
    }
    return null;
}
export function displayLabelEnabled(ctx: IActionContext, inventoryCode?: string): boolean {
    if (!inventoryCode) {
        return false;
    }
    switch (ctx.requestContext.app.config.inventoryRanges) {
        case 'lowAndOutofStock':
            if (inventoryCode !== 'AVAIL') {
                return true;
            }
            break;
        case 'outOfStock':
            if (inventoryCode === 'OOS') {
                return true;
            }
            break;
        case 'all':
            return true;
        default:
            return false;
    }
    return false;
}