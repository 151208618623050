import {
    CacheType,
    createObservableDataAction,
    IActionContext,
    IActionInput
} from '@msdyn365-commerce/core';
import { laybuyConfirmOrderAsync } from '../../../data-actions/generated/DataActionExtension.g';

/**
 * Laybuy CreateOrderInput Input Action
 */
export class ConfirmOrderInput implements IActionInput {
    public token: string = '';

    constructor (token: string) {
        this.token = token;
    }

    // TODO: Determine if the results of this get action should cache the results and if so provide
    // a cache object type and an appropriate cache key
    public getCacheKey = () => `LaybuyConfirmOrder ${this.token}`;
    public getCacheObjectType = () => `LaybuyConfirmOrder`;
    public dataCacheType = (): CacheType => 'request';
}

/**
 * TODO: Use this function to create the input required to make the action call
 */
// tslint:disable-next-line: no-any
const createInput = (args: any): IActionInput => {
    return args;
};

/**
 * TODO: Use this function to call your action and process the results as needed
 */
// tslint:disable-next-line
async function action(input: any, ctx: IActionContext) {
    const result = await laybuyConfirmOrderAsync(
        { callerContext: ctx },
        input.token
    );
    return Array.isArray(result) ? result[0] : result;
}

export const IConfirmOrderAction =  createObservableDataAction({
    action: action,
    input: createInput
});
