import React from 'react';
import { Col, Row } from 'reactstrap';

interface IProps {
    price: string;
}

const LaybuyCheckoutPriceBreakdown: React.FC<IProps> = (props) => {
    return (
        <React.Fragment>
            <p>Pay it in 6 weekly interest-free payments from <strong>${props.price}</strong></p>
            <Row noGutters={true}>
            <Col md='12' lg='6'>
                <h6>Pay by Laybuy.</h6>
                <p>Proceed to checkout. Select Laybuy as your payment method.</p>
            </Col>
            <Col md='12' lg='6'>
                <h6>Select schedule.</h6>
                <p>Choose your payment day. View your schedule and select pay now.</p>
            </Col>
        </Row>
        <Row noGutters={true}>
            <Col md='12' lg='6'>
                <h6>Complete order.</h6>
                <p>Log in or sign up. Complete your order in seconds.</p>
            </Col>
            <Col md='12' lg='6'>
                <h6>You're done.</h6>
                <p>Your items are on their way. Payments will be taken automatically each week.</p>
            </Col>
        </Row>
      </React.Fragment>
    );
};

export default LaybuyCheckoutPriceBreakdown;
