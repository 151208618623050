import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';

export function craeteShippingTypeInput(result: string): GenericInput<string> {
    return new GenericInput<string>('ShippingType', result, 'string');
}

const craeteShippingTypeInputInternal = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return craeteShippingTypeInput('');
};

export default createObservableDataAction({
    action: <IAction<string>>getGenericAction,
    input: craeteShippingTypeInputInternal
});