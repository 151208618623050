/* tslint:disable */
import { GetTransactionEntityExtensionClass, IGetTransactionEntity } from '../../../data-actions/generated/DataServiceEntities.g';
import {
    createObservableDataAction,
    CacheType,
    IActionInput,
    IActionContext,
    IAction,
} from '@msdyn365-commerce/core';
import { getTransactionRequestAsync } from '../../../data-actions/generated/DataActionExtension.g';

export const createTransactionResponseId = (id: string) => {
    const transactionPX = new GetTransactionEntityExtensionClass();
    transactionPX.Id = id;
    return transactionPX;
};

/**
 * FooInput
 * @export
 * @class FooInput
 * @implements {IActionInput}
 */
export class paymentExpressGetTransactionInput implements IActionInput {
    public transactionId: string;

    constructor(transactionId: string) {
        this.transactionId = transactionId;
    }

    public getCacheKey = () => `GetTransactionConfirm  ${this.transactionId}`;
    public getCacheObjectType = () => 'GetTransactionConfirm';
    public dataCacheType = (): CacheType => 'request';
}

const getMockInput = (actualInput?: paymentExpressGetTransactionInput) => {
    return new paymentExpressGetTransactionInput(' ');
};

export async function getTransactionAction(
    input: paymentExpressGetTransactionInput,
    ctx: IActionContext,
): Promise<IGetTransactionEntity> {
    const result = await getTransactionRequestAsync({ callerContext: ctx }, input.transactionId);
    return Array.isArray(result) ? result[0] : result;
}

export default createObservableDataAction({
    action: <IAction<IGetTransactionEntity>>getTransactionAction,
    input: getMockInput,
});
