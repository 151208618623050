// Credit card utils
/* tslint:disable */
import { CartLine, DiscountLine, GiftCard, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import payform from 'payform';

export function validateCreditCardNumber(value: string) {
    if (!value) {
        return false;
    }
    return payform.validateCardNumber(value);
}

export function validateCVC(value: string) {
    if (!value) {
        return false;
    }    
    return payform.validateCardCVC(value);
}

export function validateCardExpiry(month: any, year: any) {
    if (!month || !year) {
        return false;
    }
    return payform.validateCardExpiry(month, year);
}

/**
 * Creates an address object for Afterpay CreateOrder Api and Laybuy API
 * @param addressDetails checkoutstate address object
 */
// tslint:disable-next-line: no-any
const createAddressObject = (addressDetails: any) => {
    if (!addressDetails) {
        return undefined;
    }

    return {
        name: addressDetails.Name,
        line1: `${addressDetails.StreetNumber} ${addressDetails.Street}`,
        suburb: addressDetails.County, // suburb
        state: addressDetails.City,
        postcode: addressDetails.ZipCode,
        countryCode: addressDetails.TwoLetterISORegionName,
        phoneNumber: addressDetails.Phone
    };
};

/**
 * Creates an array of item objects for Afterpay CreateOrder Api
 * @param cartLines cart array from page Cart state
 * @param currency currency string
 */
const createOrderItemsObject = (cartLines: CartLine[], currency: string, products: SimpleProduct[] | undefined): any[] => {
  const itemsObject: any[] = [];

  cartLines.forEach((item: CartLine) => {
    const itemName = products ? products.find((prod: SimpleProduct) => prod.ItemId === item.ItemId!)?.Name : item.ItemId;
    const itemPrice: number = item.Price ? (item.Price - (item.DiscountAmount && item.Quantity ? (item.DiscountAmount / item.Quantity) : 0)) : 0;
    const itemObj = {
      name: itemName || item.ItemId, // REQUIRED
      sku: item.ItemId,
      quantity: item.Quantity, // REQUIRED
      price: { // REQUIRED
        amount: itemPrice?.toFixed(2) || 0,
        currency: currency
      }
    };

    itemsObject.push(itemObj);
  });
  return itemsObject;
}

/**
 * Creates an array of discount objects for Afterpay CreateOrder Api
 *
 * @param cartLines cart array from page Cart state
 * @param currency currency string
 */
const createDiscountsObject = (cartLines: CartLine[], giftCards: readonly GiftCard[], currency: string): any => {
  const discountsObject: any[] = [];

  // Get discount line for each item in cart
  cartLines.forEach((item: CartLine) => {
    if (item.DiscountLines && item.DiscountLines.length) {
      // for each discount line on product
      item.DiscountLines.forEach((disc: DiscountLine) => {
        const discObj = {
          displayName: disc.OfferName,
          amount: {
            amount: disc.EffectiveAmount,
            currency: currency
          }
        };
        discountsObject.push(discObj);
      });
    }
  });

  //REMOVED AS IT SEEMS TO RETURN A 400 ERROR FROM RETAIL SERVER
  // Get discount line for each gift card
  giftCards.forEach(() => { //(card: GiftCard) => {
    // const discObj = {
    //   displayName: `GiftCard-${card.Id}`,
    //   amount: {
    //     amount: card.Balance,
    //     currency: card.BalanceCurrencyCode
    //   }
    // };
    // discountsObject.push(discObj);
  });

  if (discountsObject.length <= 0) {
    return undefined;
  }

  return discountsObject;
};

/**
 * Function to scroll the page to an element
 * @param elementId element ID to scroll to
 */
const scrollToPaymentModule = (): void => {
    const element = document.getElementById('paymentAnchor');

    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }
};

const stringToBoolean = (value: string) => {
    switch(value?.trim().toLowerCase()) {
        case 'true':
        case '1':
        case 'yes':
            return true;
        default:
            return false;
    }
};

export {
    createAddressObject,
    createOrderItemsObject,
    createDiscountsObject,
    stringToBoolean,
    scrollToPaymentModule
};