import { ChargeLine } from '@msdyn365-commerce/retail-proxy';
import {
    Address,
    CartLine,
    CommerceProperty,
    GiftCard,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { createAddressObject } from './citta-payment.utils';
import {
    ILaybuyAddress,
    ILaybuyButtonData,
    ILaybuyItem,
    ILaybuyOrder
} from './LaybuyButton.props';

const createItemsObject = (data: CartLine[], products: SimpleProduct[]): ILaybuyItem[] => {
    const items: ILaybuyItem[] = [];

    data.forEach((item) => {
        items.push({
            id: item.ItemId!,
            description: products.find((prod: SimpleProduct) => prod.ItemId === item.ItemId!)?.Name || item.ItemId!,
            quantity: item.Quantity!,
            price: item.TotalAmount! / item.Quantity!,
        });
    });

    return items;
};

const createLaybuyAddressObject = (addressDetails: Address): ILaybuyAddress => {
    const {
        ThreeLetterISORegionName,
    } = addressDetails;
    const address = createAddressObject(addressDetails);

    return {
        name: address?.name,
        address1: address?.line1!,
        suburb: address?.suburb,
        city: address?.state,
        postcode: address?.postcode,
        country: ThreeLetterISORegionName!,
        phone: address?.phoneNumber
    };
};

const generatePayload = (buttonData: ILaybuyButtonData): ILaybuyOrder => {
    const {
        data,
        currency,
        returnURL
    } = buttonData;

    if (!(data.checkout.result && data.products.result)) {
        throw new Error('LaybuyButtonData missing cart or checkout data');
    }

    const {
        CartLines,
        ChargeLines,
        TotalAmount,
        TaxAmount,
        OrderNumber,
        ExtensionProperties // TODO remove extensionProps
    } = data.checkout.result.checkoutCart.cart;
    const {
        billingAddress,
        shippingAddress,
        giftCards
    } = data.checkout.result;
    const products = data.products.result;

    const billingObject: ILaybuyAddress | {} = billingAddress ? createLaybuyAddressObject(billingAddress) : {};
    const shippingObject: ILaybuyAddress | {} = shippingAddress ? createLaybuyAddressObject(shippingAddress) : {};
    const itemsObject: ILaybuyItem[] = createItemsObject(CartLines!, products);

    const giftcardTotal = giftCards.reduce((total: number, item: GiftCard) => {
        return item.Balance ? total + item.Balance : total;
    },                                     0);

    const LaybuyGiftcardItems = giftCards.filter((item: GiftCard) => item.Id)
        .map((card: GiftCard) => {
            return {
                id: `GiftCard-${card.Id}`,
                description: 'Giftcard',
                quantity: 1,
                price: -Math.abs(card.Balance!),
            };
    });

    const freightItem: ILaybuyItem[] = [];
    const freightChargeLine: 0 | ChargeLine | undefined = ChargeLines && ChargeLines.length && ChargeLines
        // @ts-ignore
        .reduce((chargeLine: ChargeLine) => chargeLine.IsShipping);

    if (freightChargeLine && freightChargeLine.IsShipping) {
        freightItem.push({
            id: freightChargeLine.ChargeLineId!,
            description: freightChargeLine.Description!,
            quantity: 1,
            price: freightChargeLine.CalculatedAmount!
        });
    }

    return {
        amount: TotalAmount! - giftcardTotal,
        currency,
        returnUrl: returnURL,
        merchantReference: OrderNumber!,
        tax: TaxAmount!,
        customer: { // TODO remove extensionProps
            firstName: ExtensionProperties!.find((prop: CommerceProperty) => prop.Key === 'FIRSTNAME')?.Value?.StringValue!,
            lastName: ExtensionProperties!.find((prop: CommerceProperty) => prop.Key === 'LASTNAME')?.Value?.StringValue!,
            email: ExtensionProperties!.find((prop: CommerceProperty) => prop.Key === 'EMAILADDRESS')?.Value?.StringValue!,
            phone: ExtensionProperties!.find((prop: CommerceProperty) => prop.Key === 'PHONENUMBER')?.Value?.StringValue!
        },
        billingAddress: billingObject,
        shippingAddress: shippingObject,
        items: [...itemsObject, ...LaybuyGiftcardItems, ...freightItem]
    };
};

export {
    createItemsObject,
    createAddressObject,
    generatePayload
};
