import * as crypto from 'crypto';


// tslint:disable-next-line
export const EMARSYS_API_BASE_URL = 'https://api.emarsys.net';

function base64Sha1(str: string) {
    const hexDigest = crypto.createHash('sha1')
        .update(str)
        .digest('hex');

    return new Buffer(hexDigest).toString('base64');
}

function getWsseHeader(user: string, secret: string) {
    // tslint:disable-next-line
    const nonce = crypto.randomBytes(16).toString('hex');
    const timestamp = new Date().toISOString();
    const digest = base64Sha1(`${nonce}${timestamp}${secret}`);

    return `UsernameToken Username="${user}", PasswordDigest="${digest}", Nonce="${nonce}", Created="${timestamp}"`;
}

export function getEmarsysHeader(user: string, secret: string) {
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-WSSE': getWsseHeader(user, secret)
    };

    return headers;
}

export function generateSuccessResponse(message: string) {
    return {
        Result: 'Success',
        Message: '',
        ErrorMessage: message
    };
}


export function generateErrorResponse(errorMessageString: string) {
    const errorResponse = 'Sorry, there was an issues while entering the competition, please try again later or contact support';
    return {
        Result: 'Error',
        Message: errorResponse,
        ErrorMessage: errorMessageString
    };
}

export function generateNotifyErrorResponse(errorMessageString: string) {
    const errorResponse = 'We were unable to manage this request';
    return {
        Result: 'Error',
        Message: errorResponse,
        ErrorMessage: errorMessageString
    };
}

export function generateContactPayload(email: string, firstName: string, lastName: string) {
    return  {
        key_id: '3',
        3: email,
        31: '1', // sets opt in true
        1: firstName,
        2: lastName
    };
}

export function generateEmailContactPayload(email: string, firstName: string, lastName: string, phoneNumber?: string, postCode?: string) {
    let response  = {
        key_id: '3',
        3: email,
        31: '1', // sets opt in true
        1: firstName,
        2: lastName
    };
    if (phoneNumber) {
        response['15'] = phoneNumber
    }
    if (postCode) {
        response['13'] = postCode
    }
    return response;
}

export function genterateWishlistPayload(email: string, itemId: string) {
    return  {
        key_id: '3',
        events: [
            {
                external_id: email,
                event_time: new Date().toISOString(),
                wishlist_content: [
                    {
                        item_id: itemId,
                        quantity: 1
                    }
                ]
            }
        ]
    };
}

export function generateEventPayload(email: string) {
    return  {
        key_id: '3',
        // tslint:disable-next-line
        external_id: email
    };
}
