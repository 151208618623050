import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { IAccordionExpandedState } from './accordion-state';

export function createAccordionStateInput(result: IAccordionExpandedState): GenericInput<IAccordionExpandedState> {
    return new GenericInput<IAccordionExpandedState>('accordionExpandedState', result, 'IAccordionExpandedState');
}

const createAccordionStateInputInternal = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return createAccordionStateInput({});
};

export default createObservableDataAction({
    action: <IAction<IAccordionExpandedState>>getGenericAction,
    input: createAccordionStateInputInternal
});
