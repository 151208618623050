import MsDyn365 from '@msdyn365-commerce/core';
import {
    Cart
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { observable } from 'mobx';
import CittaTelemetry from './citta-telemetry';

/**
 *
 * PlaceOrderStore handle states
 *
 */
export class TelemetryStore {
    @observable private cart: Cart = { Id: 'N/A', OrderNumber: 'N/A', TotalItems: undefined };
    @observable private cittaTelemetry: CittaTelemetry;
    @observable private urlParams: URLSearchParams;
    @observable private redirectCartId: string;

    constructor(cittaInstrumentationKey: string) {
        this.cittaTelemetry = new CittaTelemetry(cittaInstrumentationKey);

        let url: string = '';
        if (MsDyn365.isBrowser) {
            url = decodeURI(window.location.search);
        }
        this.urlParams = new URLSearchParams(url);
        this.redirectCartId = this.urlParams.has('cartid') ? this.urlParams.get('cartid')! : 'N/A';
    }

    public setCart = (cart: Cart) => {
        this.cart = cart;
    }

    public logMessage = (message: string, cart: Cart = this.cart) => {
        const { Id, OrderNumber, TotalItems } = cart;

        const logMessage = `Message: ${message} - CartId: ${Id} - RedirectCartId: ${this.redirectCartId} - ChannelRefId: ${OrderNumber} - TotalItem: ${TotalItems || 'N/A'}`;
        this.cittaTelemetry.trace(logMessage);
    }

    public logError = (error: Error, message: string, cart: Cart = this.cart) => {
        const { Id, OrderNumber, TotalItems } = cart;

        const logMessage = `Message: ${message} - CartId: ${Id} - RedirectCartId: ${this.redirectCartId} - ChannelRefId: ${OrderNumber} - TotalItem: ${TotalItems || 'N/A'} - ${message}`;
        this.cittaTelemetry.exception(error, logMessage);
    }
}
