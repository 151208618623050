import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction, IActionInput, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { IProductQuantityState } from './product-quantity-state';

export function createProductQuantityStateInput(result: IProductQuantityState): GenericInput<IProductQuantityState> {
    return new GenericInput<IProductQuantityState>('productQuantityState', result, 'IProductQuantityState');
}

const createProductQuantityStateInputInternal = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return createProductQuantityStateInput({});
};

export default createObservableDataAction({
    action: <IAction<IProductQuantityState>>getGenericAction,
    input: createProductQuantityStateInputInternal
});
