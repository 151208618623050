/* tslint:disable */
import { GetTransactionIdEntityExtensionClass, IGetTransactionIdEntity } from '../../../data-actions/generated/DataServiceEntities.g';
import {
    createObservableDataAction,
    CacheType,
    IActionInput,
    IActionContext,
    IAction,
} from '@msdyn365-commerce/core';
import { getTransactionIdRequestAsync } from '../../../data-actions/generated/DataActionExtension.g';

export const createTransactionResponseId = (id: string, amount: string, currency: string, returnUrl: string) => {
    const transactionPX = new GetTransactionIdEntityExtensionClass();
    transactionPX.Id = id;
    transactionPX.Amount = amount;
    transactionPX.Currency = currency;
    transactionPX.TxnType = 'Purchase';
    transactionPX.ReturnURL = returnUrl;
    return transactionPX;
};

/**
 * FooInput
 * @export
 * @class FooInput
 * @implements {IActionInput}
 */
export class paymentExpressInput implements IActionInput {
    public transactionId: string;
    public Amount: string;
    public Currency: string;
    public returnUrl: string;
    public transactionData: any;

    constructor(transactionId: string, Amount: string, Currency: string, returnUrl: string) {
        this.transactionId = transactionId;
        this.Amount = Amount;
        this.Currency = Currency;
        this.returnUrl = returnUrl;
        this.transactionData = createTransactionResponseId(
            this.transactionId,
            this.Amount,
            this.Currency,
            this.returnUrl
        );
    }

    public getCacheKey = () => `TransactionDataId ${this.transactionData.Id}`;
    public getCacheObjectType = () => 'TransactionDataId';
    public dataCacheType = (): CacheType => 'request';
}

const getMockInput = (actualInput?: paymentExpressInput) => {
    // TODO: parse actualInput instead of sending static mock response
    return new paymentExpressInput('', '', '', '');
};

export async function getTransactionIdAction(
    input: paymentExpressInput,
    ctx: IActionContext,
): Promise<IGetTransactionIdEntity> {
    const result = await getTransactionIdRequestAsync({ callerContext: ctx }, <
        IGetTransactionIdEntity
    >input.transactionData);
    return Array.isArray(result) ? result[0] : result;
}

export default createObservableDataAction({
    action: <IAction<IGetTransactionIdEntity>>getTransactionIdAction,
    input: getMockInput,
});
