import { CommerceProperty } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

export const getExtensionPropsLength = (extensionProps: CommerceProperty[]): string => {
    const props = extensionProps.map((object: CommerceProperty) => {
        return { key: object.Key, length: object.Value?.StringValue?.length };
    });
    return JSON.stringify(props);
};

export const maskGiftCard = (giftCardNo: string): string => {
    return giftCardNo.replace(/\d(?=\d{4})/g, '*');
};
